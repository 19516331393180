html{
  font-size: 16px;
}

body{
  position: relative;
  &.menu-in{
    overflow: hidden;
    #mobile-header{
      @include pad{
        transform: translateX(0);
      }
    }
  }
}

*{
  font-family: "Noto Sans TC",Arial,sans-serif;
  box-sizing: border-box;
}

.btn{
  border-radius: 5px;
  &.btn-main{
    font-size: rem(18);
    padding: 12px 20px;
    min-width: 120px;
  }
  &[disabled]{
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.btn-link{
  color: $text;
  text-decoration: none;
  &:hover{
    color: $darkGreen;
  }
}

.btn-light{
  color: $darkGreen;
}

.container{
  padding: 0 40px;
  @include media(min,1200){
    max-width: 1200px;
  }
  @include media(min,1400){
    max-width: 1320px;
  }
  @include pad{
    padding: 0 20px;
  }
  &.fluid{
    max-width: 1440px;
  }
  &.medium{
    max-width: 850px;
  }
  &.l-medium{
    max-width: 1020px;
  }
}

.img-fluid{
  width: 100%;
}

#content{
  overflow: hidden;
}

.page-banner{
  margin-bottom: 100px;
  position: relative;
  @include pad{
    margin-bottom: 180px;
  }
  .container{
    height: 300px;
    @include pad{
      height: 200px;
    }
    > img{
      display: block;
      position: absolute;
      bottom: 50%;
      right: 50%;
      transform: translate(50%,50%);
      @include size(auto,100%);
    }
  }
  .banner-content{
    position: absolute;
    bottom: -30px;
    left: 40px;
    z-index: 2;
    @include media(max,1000){
      left: 50%;
      transform: translateX(-50%);
    }
    @include pad{
      bottom: -130px;
    }
    h2{
      padding: 20px 60px;
      margin: 0;
      position: absolute;
      z-index: 4;
      background-color: $black;
      font-size: rem(40);
      line-height: 1;
      color: $white;
      bottom: calc(100% - 35px);
      left: -30px;
      white-space: nowrap;
      @include pad{
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .info{
      width: 485px;
      padding: 50px 40px 30px 50px;
      background-color: $white;
      border: solid 1px $black;
      position: relative;
      z-index: 3;
      @include pad{
        padding: 50px 40px 25px;
        width: 360px;
        transform: perspective(360px) rotateX(5deg);
      }
      p{
        margin: 0;
        font-size: rem(18);
        line-height: 26px;
        @include pad{
          transform: perspective(360px) rotateX(-5deg);
        }
      }
    }
    &:after,&:before{
      content: "";
      display: block;
      position: absolute;
      @include size(100%);
      border: solid 1px $black;
      left: 0;
      top: 0;
      @include pad(min){
        @include t;
      }
    }
    &:before{
      background-color: $green;
      @include pad{
        top: 20px;
        left: 0;
        transform: perspective(360px) rotateX(5deg) scale(0.95);
      }
    }
    &:after{
      background-color: $yellow;
      @include pad{
        top: 10px;
        left: 0;
        transform: perspective(360px) rotateX(5deg) scale(0.975);
      }
    }
    &.active{
      @include pad(min){
        &:before{
          top: 20px;
          left: 20px;
        }
        &:after{
          top: 10px;
          left: 10px;
        }
      }
    }
  }
}

#pagination-container{
  .paginationjs{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    @include pad{
      flex-direction: column-reverse;
      align-items: center;
    }
  }
  .paginationjs-nav{
    line-height: 35px;
    font-size: rem(14);
    padding: 0 3px;
  }
  .paginationjs-size-changer{
    select{
      padding-left: 5px;
      padding-right: 5px;
      border-color: $border;
      height: 35px;
      margin-left: 3px;
      border-radius: 3px;
      font-size: rem(14);
    }
  }
  .paginationjs-pages{
    @include pad{
      padding: 15px 0;
    }
    ul{
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0;
      li{
        padding: 0 3px;
        list-style-type: none;
        line-height: 1;
        a{
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          text-align: center;
          @include size(35px);
          letter-spacing: 0;
          font-size: rem(14);
          border: solid 1px $border;
          color: $black;
          cursor: pointer;
          &:hover{
            background-color: $background;
          }
        }
        &.active{
          a{
            border-color: $darkGreen;
            color: $darkGreen;
          }
        }
      }
    }
  }
}