.page-learn{
  padding-top: 70px;
  padding-bottom: 80px;
  background-image: url(../images/img-learn-bg.svg);
  background-size: 280px auto;
  min-height: calc(100vh - 180px);
  position: relative;
  @include media(max,990){
    padding-top: 50px;
  }
  &:before{
    content: "";
    display: block;
    // padding-bottom: 1000px;
    background-color: $white;
    @include size(1100px);
    border-radius: 50%;
    position: absolute;
    right: 50%;
    top: -60px;
    transform: translateX(50%);
    box-shadow: 0 40px 0 #F0E332;
    @include media(max,990){
      top: 85px;
      @include size(1250px);
    }
  }
  .container{
    position: relative;
    z-index: 2;
  }
  h2.title{
    margin: 0 auto 30px;
    width: fit-content;
    span{
      display: none;
    }
    img{
      @include size(auto,97px);
    }
  }
  p{
    font-size: rem(18);
    color: $text;
  }
}

.learn-certificate{
  display: flex;
  flex-wrap: wrap;
  padding-top: 60px;
  margin: 0 auto;
  max-width: 900px;
  @include media(max,990){
    padding-top: 30px;
    max-width: 500px;
  }
  @include media(max,534){
    margin-left: -7px;
    width: calc(100% + 14px);
  }
  .certificate{
    padding: 0 25px;
    width: 25%;
    @include media(max,990){
      padding: 0 17px;
      margin-bottom: 30px;
      width: 50%;
    }
    &.unfinished{
      filter: grayscale(1);
      .btn-link{
        opacity: 0;
        pointer-events: none;
      }
    }
    .title{
      @extend %title;
      margin-bottom: 25px;
    }
    figure{
      margin-bottom: 35px;
      position: relative;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 0 6px 0 $black;
      background-color: $black;
      &:after{
        content: "";
        display: block;
        padding-bottom: 100%;
      }
      img{
        position: absolute;
        bottom: 50%;
        right: 50%;
        transform: translate(50%,50%);
        @include size(100%);
        object-fit: cover;
      }
    }
    a.btn{
      display: block;
      + a.btn{
        margin-top: 10px;
      }
    }
  }
}

@function pdfscale($size,$scale:3){
  @return calc($size * $scale);
}
#certificate{
  display: none;
  padding-top: pdfscale(98px);
  // position: fixed;
  // right: 50%;
  // top: 80px;
  // transform: translateX(50%);
  background-image: url(../images/logo.svg),url(../images/img-member-bg-2.svg),url(../images/img-member-bg-1.svg);
  background-repeat: no-repeat;
  background-size: pdfscale(128px) pdfscale(24px),cover,cover;
  background-position: center 95%,center center,center center;
  // width: calc(845px * $pdfscale);
  // height: calc(600px * $pdfscale);
  width: pdfscale(845px);
  height: pdfscale(600px);
  z-index: 2;
  .info{
    // width: calc(705px * 2);
    // height: calc(422px * 2);
    width: pdfscale(715px);
    height: pdfscale(422px);
    margin: 0 auto;
    // padding: 0 94px 66px;
    padding: 0 pdfscale(94px) pdfscale(66px);
    background-color: $white;
    border-radius: 30px;
    position: relative;
    .star{
      position: absolute;
    }
    .star-1{
      @include size(pdfscale(45px));
      top: 0;
      left: 0;
      transform: translate(-40%,-40%);
    }
    .star-2{
      @include size(pdfscale(45px));
      top: 50%;
      right: 0;
      transform: translate(50%,-50%);
    }
    .title{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto pdfscale(20px);
      padding-bottom: pdfscale(20px);
      transform: translateY(pdfscale(-40px));
      background-image: url(../images/img-certificate-title.svg);
      background-size: pdfscale(517px) pdfscale(80px);
      background-repeat: no-repeat;
      width: pdfscale(517px);
      height: pdfscale(80px);
      h3{
        margin: 0;
        color: $white;
        font-size: rem(pdfscale(34));
      }
    }
    .content{
      display: flex;
      align-items: flex-start;
      figure{
        display: block;
        margin: pdfscale(37px) 0 0;
        width: pdfscale(170px);
        border-radius: pdfscale(170px);
        overflow: hidden;
        // box-shadow: 0 pdfscale(6px) 0 $black;
        border-bottom: solid pdfscale(6px) $black;
        position: relative;
        background-color: $black;
        img{
          position: absolute;
          top: 0;
          left: 0;
          @include size(100%);
          object-fit: cover;
        }
        &:after{
          content: "";
          display: block;
          padding-bottom: 100%;
        }
      }
      figcaption{
        padding-left: pdfscale(45px);
        color: $black;
        .push{
          margin-bottom: pdfscale(40px);
        }
        h4{
          margin-bottom: pdfscale(15px);
          font-size: rem(pdfscale(30));
        }
        p{
          margin: 0;
          font-size: rem(pdfscale(20));
          line-height: pdfscale(45px);
        }
        h5{
          display: flex;
          align-items: center;
          margin: 0;
          font-weight: 400;
          font-size: rem(pdfscale(16));
          img{
            margin-left: pdfscale(23px);
            width: pdfscale(148px);
            height: pdfscale(49px);
          }
        }
      }
    }
  }
}

.certificate-loading{
  padding: 30px 60px;
  p{
    margin-bottom: 20px;
    font-size: rem(20);
    color: $black;
  }
  .loading{
    display: flex;
    align-items: center;
    color: $darkGreen;
    .spinner-border{
      display: block;
      margin-right: 10px;
    }
  }
}