header#header{
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 4px 6px rgba($black,0.2);
  background-color: $white;
  .brand{
    padding: 15px 0;
    @include pad{
      padding: 20px 0;
    }
    h1.logo{
      margin: 0;
      a,img{
        display: block;
        @include size(auto,30px);
      }
    }
  }
  .menu{
    @include pad{
      display: none;
    }
    ul{
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      li{
        padding: 20px 0;
        margin: 0 rem(19);
        list-style-type: none;
        a{
          display: block;
          text-decoration: none;
          line-height: 20px;
          font-size: rem(16);
          color: $header;
          &:hover{
            color: $black;
          }
        }
        &.active{
          > a{
            pointer-events: none;
            color: $darkGreen;
          }
        }
        &:first-of-type{
          margin-left: 0;
        }
        &:last-of-type{
          margin-right: 0;
        }
      }
    }
  }
  .member-wrap{
    display: flex;
    justify-content: flex-end;
    width: 140px;
    @include pad{
      display: none;
    }
  }
}

.member-wrap{
  a.login{
    display: block;
    margin: 10px 0;
    padding: 10px 25px;
    // width: 140px;
    width: fit-content;
    font-size: rem(18);
    line-height: 18px;
    color: $black;
    text-align: center;
    text-decoration: none;
    background-color: $yellow;
    border: solid 1px $black;
    border-radius: 50px;
    @include t;
    &:hover{
      box-shadow: 0 0 10px rgba($black,0.2);
    }
  }
  .logined{
    padding: 8px 0;
    position: relative;
    cursor: default;
    .avatar{
      @include size(44px);
      font-size: rem(18);
      line-height: 44px;
      text-align: center;
      background-color: $purple;
      color: $white;
      border-radius: 50%;
      text-transform: uppercase;
    }
    .member-menu{
      padding: 20px 15px;
      position: absolute;
      width: 120px;
      top: 100%;
      right: 0;
      background-color: $white;
      border: solid 1px $black;
      border-radius: 5px;
      @include t(0.3);
      pointer-events: none;
      opacity: 0;
      ul{
        margin: 0;
        padding: 0;
        li{
          list-style-type: none;
          text-align: center;
          color: $text;
          a{
            display: block;
            text-decoration: none;
            color: $black;
            line-height: 30px;
          }
          &.hr{
            margin: 15px 0;
            height: 1px;
            background-color: $border;
          }
        }
      }
    }
    &:hover{
      .member-menu{
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}

#mobile-header{
  position: fixed;
  padding-top: 30px;
  padding-bottom: 50px;
  z-index: 99;
  background-color: $white;
  @include size(100%,calc(100% - 70px));
  overflow: scroll;
  @include pad{
    @include t;
  }
  &::-webkit-scrollbar{
    display: none;
  }
  transform: translateX(100%);
  .container{
    max-width: 340px;
  }
  .member-wrap{
    a.login{
      margin: 0 auto;
      font-size: rem(28);
      line-height: 30px;
    }
    .logined{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      .avatar{
        @include size(60px);
        line-height: 60px;
        text-transform: uppercase;
      }
      p{
        margin: 0 0 0 20px;
        font-size: rem(20);
        color: $text;
      }
      .member-menu{
        padding: 0;
        margin-top: 30px;
        position: relative;
        top: 0;
        opacity: 1;
        pointer-events: all;
        border: none;
        width: 100%;
        ul{
          li{
            text-align: center;
            + li{
              margin-top: 20px;
            }
            a{
              color: $header;
              text-decoration: none;
              font-size: rem(28);
              line-height: 40px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
  .title{
    margin-bottom: 30px;
    position: relative;
    height: 40px;
    &:before{
      content: "";
      display: block;
      position: absolute;
      bottom: 50%;
      right: 50%;
      @include size(100%,1px);
      background-color: $border;
      transform: translate(50%,50%);
    }
    h3{
      position: relative;
      z-index: 2;
      width: fit-content;
      padding: 0 10px;
      margin: 0 auto;
      line-height: 40px;
      font-size: 16px;
      background-color: $white;
      color: $header;
      font-weight: 400;
    }
  }
  .menu{
    padding-top: 30px;
    ul{
      margin: 0;
      padding: 0;
      li{
        list-style-type: none;
        text-align: center;
        + li{
          margin-top: 20px;
        }
        a{
          color: $header;
          text-decoration: none;
          font-size: rem(28);
          line-height: 40px;
          font-weight: 400;
        }
        &.active{
          > a{
            pointer-events: none;
            color: $darkGreen;
          }
        }
      }
    }
  }
  .bottom{
    padding-top: 30px;
    text-align: center;
    a{
      display: block;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      margin-top: -20px;
      text-decoration: none;
      color: $gray;
      font-size: rem(28);
    }
  }
}

#mobile-toggle{
  @include size(30px);
  @include t;
  display: none;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  position: absolute;
  z-index: 1000;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  @include pad{
    display: block;
  }
  span{
    @include size(100%,2px);
    @include t(0.2);
    display: block;
    background: none;
    border-radius: 10px;
    position: absolute;
    bottom: 50%;
    right: 50%;
    transform: translate(50%,50%);
    &:after,&:before{
      @include size(100%);
      @include t(0.2);
      content: "";
      display: block;
      position: absolute;
      background-color: $header;
      border-radius: 10px;
      right: 50%;
      transform: translateX(50%);
    }
    &:after{
      top: 5px;
    }
    &:before{
      bottom: 5px;
    }
  }
  &.active{
    span{
      @include t(0);
      background-color: transparent;
      &:after,&:before{
        background-color: $black;
      }
      &:after{
        top: 0;
        transform: translateX(50%) rotate(45deg);
      }
      &:before{
        bottom: 0;
        transform: translateX(50%) rotate(-45deg); 
      }
        
    }
  }
}

#alert-wrap{
  position: fixed;
  bottom: 50px;
  right: 0px;
  z-index: 1056;
  max-width: 95%;
  opacity: 0;
  pointer-events: none;
  @include t;
  .alert{
    margin-bottom: 0;
    font-size: rem(18);
  }
  &.show{
    opacity: 1;
    right: 30px;
  }
}