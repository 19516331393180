.page-profile{
  padding: 70px 0;
  min-height: calc(100vh - 180px);
  background-color: $background;
  @include pad(min){
    display: flex;
    align-items: center;
  }
  .container{
    max-width: 1000px;
    min-height: 600px;
    background-image: url(../images/img-profile-bg.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    @include phone{
      background: none;
      background-image: url(../images/img-profile-mobile-icon-1.svg),url(../images/img-profile-mobile-icon-2.svg);
      background-size: 57px 80px,80px 80px;
      background-position: 5.5% 78px,100% 91%;
      background-repeat: no-repeat;
    }
    .title{
      margin-bottom: 16px;
      img{
        display: block;
        margin: 0 auto;
        @include size(313px,auto);
      }
    }
    .info{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0 auto 40px;
      padding: 40px 50px;
      max-width: 390px;
      border: solid 4px $black;
      box-shadow: 6px 6px 0 $black;
      border-radius: 5px;
      background-color: $white;
      .placeholder{
        + .placeholder{
          margin-top: 6px;
        }
      }
      hr{
        margin: 30px 0 30px -20px;
        width: calc(100% + 40px);
      }
      h3{
        margin: 0 0 5px;
        font-size: rem(24);
        font-weight: 400;
      }
      p{
        margin: 0;
        font-size: rem(18);
        + span{
          margin-top: 20px!important;
        }
      }
      span{
        color: $text;
        font-size: rem(14);
      }
      a{
        color: $darkGreen;
        text-decoration: none;
        font-size: rem(16);
      }
    }
    p.bottom{
      margin: 00;
      font-size: rem(16);
      line-height: rem(24);
      text-align: center;
      a{
        color: $black;
        text-decoration: none;
      }
    }
  }
}

