.page-admin{
  padding-top: 60px;
  padding-bottom: 150px;
  .dropdown-toggle{
    &:after{
      display: none;
    }
  }
  .dropdown-menu{
    width: fit-content;
    min-width: 0;
  }
  .form-wrap{
    flex-direction: column;
  }
  #filter{
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    @include pad{
      flex-wrap: wrap;
    }
    // .d-flex{
    //   width: 100%;
    // }
    .btn{
      flex-shrink: 0;
      @include pad{
        margin-bottom: 15px;
        width: 100%;
      }
    }
    .form-floating,.input-group{
      margin: 0 15px 0 0;
      width: 250px;
      @include mobile{
        width: auto;
      }
      @include pad{
        margin-bottom: 15px;
        margin-right: 0;
        width: 100%;
      }
    }
  }
  .head{
    margin-bottom: 50px;
    @include pad{
      padding-bottom: 30px;
      margin-bottom: 20px;
      padding-top: 15px;
      border-bottom: solid 1px rgba($border,0.25);
      position: relative;
      h2{
        font-size: rem(24);
      }
      .btns,.btn-group{
        position: absolute;
        bottom: 100%;
        right: 0;
      }
    }
  }
}

#member-table{
  margin: 20px 0 40px;
}

.table{
  .r-h{
    @include pad{
      display: none;
    }
  }
  thead{
    th{
      font-size: rem(16);
      background-color: #E2EDE8;
      font-weight: 400;
      white-space: nowrap;
    }
  }
  tbody{
    &.loading{
      td{
        filter: blur(2px);
      }
    }
    td{
      .dropdown-toggle{
        padding: 0;
        background-image: url(../images/img-tool.svg);
        background-repeat: no-repeat;
        background-position: center center;
        @include size(40px);
      }
      a{
        text-decoration: none;
        color: $black;
      }
    }
  }
  th,td{
    vertical-align: middle;
  }
  th{
    padding: 20px 15px;
  }
  td{
    padding: 15px;
  }
}