%title{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  padding-bottom: 8px;
  position: relative;
  z-index: 2;
  height: 50px;
  background-image: url(../images/img-title.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  color: $black;
  @include pad{
    margin-bottom: 10px;
  }
  h3{
    margin: 0;
    padding-left: 2px;
    font-size: rem(22);
    letter-spacing: 2px;
    font-weight: 500;
    text-align: center;
  }
}