.home-banner{
  .mobile{
    height: 700px;
    background-image: url(../images/img-home-banner-mobile.png),url(../images/img-home-banner-mobile-bg.png);
    background-size: auto 82%,contain;
    background-position: 45% center,center center;
    background-repeat: no-repeat,repeat;
  }
  .swiper-pagination{
    display: flex;
    flex-direction: column;
    position: absolute;
    width: auto;
    top: auto;
    bottom: 50px;
    right: 10px;
    left: auto;
    @include pad{
      flex-direction: row;
    }
    .swiper-pagination-bullet{
      margin: 3px;
      @include size(30px);
      background-color: $title;
      color: $white;
      line-height: 26px;
      padding: 0;
      font-size: rem(14);
      border: solid 2px $white;
      @include pad{
        @include size(25px);
        line-height: 21px;
        font-size: rem(12);
      }
    }
    @include pad{
      bottom: 15px;
      right: 10px;
    }
  }
}

.home-carousel{
  margin-top: -3.2%;
  padding-top: 92px;
  background-color: $background;
  padding-bottom: 60px;
  padding-top: 80px;
  @include pad{
    padding-top: 40px;
    padding-bottom: 0;
    margin-top: 0;
  }
  .title{
    display: flex;
    justify-content: center;
    margin-bottom: -30px;
    position: relative;
    z-index: 2;
    @include pad{
      margin-bottom: 20px;
    }
    img{
      &:nth-of-type(1){
        margin-right: 10px;
        @include size(150px,auto);
        @include pad{
          width: 80px;
        }
      }
      &:nth-of-type(2){
        @include size(auto,174px);
        @include pad{
          height: 100px;
        }
      }
      &:nth-of-type(3){
        @include size(150px,auto);
        margin-left: 10px;
        transform: translateY(-30px);
        @include pad{
          width: 80px;
        }
      }
    }
  }
  .container{
    max-width: 1140px;
  }
  .swiper-slide{
    a,img{
      display: block;
      width: 100%;
    }
  }
  .swiper-pagination{
    position: relative;
    top: 0;
    bottom: auto;
    left: 0;
    padding-top: 15px;
    @include pad{
      padding-top: 0;
    }
    .swiper-pagination-bullet{
      background-color: #E4E4E4;
      opacity: 1;
      @include size(100px,4px);
      border-radius: 0;
      @include pad{
        width: 40px;
      }
      &.swiper-pagination-bullet-active{
        background-color: $darkGreen;
      }
    }
  }
}

.home-intro{
  margin-top: -3.2%;
  padding-top: 92px;
  background-image: url(../images/img-home-bg.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
  @include pad{
    padding-top: 0;
    margin-top: 0;
    background-image: url(../images/img-home-bg-mobile.png);
  }
  .intro-content{
    display: flex;
    align-items: center;
    padding: 70px 15%;
    background-color: $white;
    border-radius: 50%;
    position: relative;
    @include media(max,1200){
      padding: 70px 5%;
    }
    @include pad{
      padding: 40px 0 150px;
      align-items: flex-start;
      flex-direction: column;
      background: none;
    }
    .title{
      width: 174px;
      flex-shrink: 0;
      @include pad{
        margin-bottom: 15px;
      }
    }
    .text-block{
      padding-left: 30px;
      @include pad{
        padding-left: 0;
      }
      p{
        font-size: rem(18);
        line-height: 34px;
        color: $black;
        &:last-of-type{
          margin-bottom: 0;
        }
      }
    }
    [class*="icon"]{
      position: absolute;
      @include pad(min){
        transition-duration: 0.5s;
        transition-delay: 1s;
      }
    }
    .icon-1{
      width: 60px;
      top: 30px;
      right: 100px;
      @include media(max,1200){
        top: 0;
        right: 0;
      }
      @include pad{
        top: auto;
        bottom: 55px;
        right: auto;
        left: 0;
      }
    }
    .icon-2{
      width: 60px;
      bottom: -24px;
      left: 35%;
      @include media(max,1200){
        bottom: 0;
        left: 0;
      }
      @include pad{
        bottom: auto;
        top: 180px;
        left: auto;
        right: 20px;
      }
    }
    .icon-3{
      width: 600px;
      bottom: -25px;
      right: 50px;
      @include media(max,1200){
        width: 400px;
        right: 0;
        bottom: 0;
      }
      @include pad{
        display: none;
      }
    }
  }
  .plan-content{
    margin: 0 auto;
    padding-top: 200px;
    max-width: 930px;
    position: relative;
    @include pad{
      margin-top: -120px;
      padding-top: 240px;
    }
    [class*="icon"]{
      position: absolute;
      overflow: hidden;
    }
    .icon-1{
      width: 720px;
      top: 0;
      right: -60px;
      @include pad{
        width: 250px;
        right: 0;
      }
    }
    .icon-2{
      width: 195px;
      left: -90px;
      top: 80px;
      @include pad{
        display: none;
      }
    }
    .row{
      position: relative;
      z-index: 2;
      @include phone{
        margin: 0 auto;
        width: 280px;
      }
    }
    .plan{
      &:nth-of-type(even){
        padding-top: 80px;
        @include phone{
          padding-top: 0;
        }
      }
      a{
        display: block;
        text-decoration: none;
      }
      figure{
        margin: 0;
      }
      .title{
        margin-top: -25px;
        @extend %title;
      }
      p{
        font-size: rem(18);
        color: $black;
      }
    }
  }
}

.home-teacher{
  padding-top: 80px;
  position: relative;
  .container{
    @include pad{
      padding: 0;
    }
  }
  .title{
    margin: 0 auto -50px;
    width: 402px;
    @include pad{
      width: 300px;
      margin-bottom: -35px;
    }
  }
  [class*="icon"]{
    position: absolute;
  }
  .icon-1{
    top: 0;
    right: 80px;
    width: 200px;
    @include pad{
      display: none;
    }
  }
  .teacher-content{
    padding: 150px 70px 70px;
    background-image: url(../images/img-home-teacher-bg.svg);
    background-size: cover;
    border-radius: 10px;
    overflow: hidden;
    min-height: 100px;
    @include pad{
      padding: 80px 40px 60px;
    }
    .d-flex{
      margin-left: -25px;
      width: calc(100% + 50px);
    }
    .teacher{
      width: 50%;
      padding: 0 25px;
      margin-bottom: 150px;
      @include media(max,1100){
        width: 100%;
        max-width: 600px;
        margin: 0 auto 60px;
      }
      &:nth-of-type(even){
        transform: translateY(150px);
        @include media(max,1100){
          transform: none;
          .info{
            padding-right: 50px;
            padding-left: 20px;
            @include pad{
              padding-right: 20px;
            }
            figure{
              left: auto;
              right: 0;
              transform: translate(30px,-30px);
            }
            &:after{
              left: auto;
              right: 10px;
            }
            h3{
              padding-left: 20px;
              padding-right: 140px;
              text-align: right;
              @include pad{
                padding-right: 90px;
              }
              &:before{
                left: auto;
                right: 0;
              }
            }
            p{
              padding-left: 20px;
              padding-right: 140px;
              @include pad{
                padding-right: 20px;
              }
            }
          }
        }
      }
      &:last-of-type{
        margin-bottom: 0;
      }
      .info{
        padding-left: 15%;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 20px;
        position: relative;
        @include media(max,1100){
          padding-left: 50px;
        }
        @include pad{
          padding-left: 20px;
        }
        &:before,&:after{
          content: "";
          display: block;
          position: absolute;
          @include size(85%,100%);
          background-color: $white;
          border: solid 3px $black;
          border-radius: 10px;
          overflow: hidden;
          @include media(max,1100){
            width: 100%;
          }
        }
        &:before{
          top: 0;
          right: 0;
          z-index: 2;
        }
        &:after{
          z-index: 1;
          top: 10px;
          right: -10px;
        }
        h3{
          background-image: url();
        }
        figure{
          margin: 0;
          position: absolute;
          left: 0;
          top: 0;
          transform: translateY(-35%);
          border: solid 4px $black;
          background-color: $black;
          box-shadow: 0 6px 0 $black;
          // border-bottom-width: 10px;
          border-radius: 50%;
          width: 200px;
          overflow: hidden;
          z-index: 5;
          @include media(max,1100){
            transform: translate(-30px,-30px);
          }
          @include pad{
            width: 120px;
            transform: translate(-30px,-20px);
          }
          &:after{
            content: "";
            display: block;
            padding-bottom: 100%;
          }
          img{
            position: absolute;
            top: 0;
            left: 0;
            @include size(100%);
            object-fit: cover;
          }
        }
        h3{
          margin: 0 0 20px;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 140px;
          position: relative;
          z-index: 4;
          font-size: rem(26);
          font-weight: 500;
          line-height: 38px;
          white-space: nowrap;
          @include pad{
            padding-left: 90px;
            font-size: rem(24);
          }
          &:before{
            content: "";
            display: block;
            background-color: $background;
            position: absolute;
            top: 0;
            left: 0;
            @include size(90%,100%);
            border-radius: 50px;
            pointer-events: none;
            @include pad{
              width: 95%;
            }
          }
          span{
            position: relative;
            z-index: 2;
            color: $black;
          }
        }
        p{
          padding-left: 140px;
          margin-bottom: 12px;
          position: relative;
          z-index: 4;
          font-size: rem(18);
          color: $text;
          @include pad{
            padding-left: 60px;
          }
          &.specialty{
            font-weight: 500;
            color: $black;
          }
        }
      }
    }
  }
}

.home-faq{
  padding-top: 70px;
  padding-bottom: 100px;
  @include pad{
    padding-top: 50px;
    padding-bottom: 60px;
  }
  .container{
    max-width: 900px;
  }
  .title{
    margin: 0 auto 30px;
    width: 360px;
    @include pad{
      width: 300px;
    }
  }
  .faq-list{
    @include phone{
      margin-left: -20px;
      width: calc(100% + 40px);
    }
    ul{
      padding: 0;
      margin: 0;
      li{
        padding: 25px 0;
        list-style-type: none;
        font-size: rem(18);
        border-bottom: solid 1px $border;
        @include t;
        @include phone{
          padding: 20px 0;
        }
        &.active{
          background-color: rgba($gray,0.1);
          .head{
            &:after{
              display: none;
            }
          }
        }
        .head{
          padding: 0 40px;
          position: relative;
          cursor: pointer;
          @include phone{
            padding: 0 20px;
          }
          h3{
            display: flex;
            margin-bottom: 0;
            padding-right: 40px;
            font-size: rem(18);
            line-height: 30px;
            font-weight: 400;
            color: $black;
            span{
              display: block;
              width: 30px;
              flex-shrink: 0;
            }
          }
          &:before,&:after{
            content: "";
            display: block;
            @include size(15px,2px);
            background-color: $black;
            position: absolute;
            right: 40px;
            transform-origin: 50% 50%;
            top: 14px;
          }
          &:after{
            transform: rotate(90deg);
          }
        }
        .body{
          display: none;
          padding: 10px 40px 0 70px;
          @include phone{
            padding: 5px 20px 0 50px;
          }
          p{
            font-size: rem(16);
            line-height: 30px;
            color: $text;
            &:last-child{
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.float-link
{
  position: fixed;
  right: 2%;
  bottom: 3%;
  width: 110px;
  z-index: 99;
  transition: transform 0.5s;
  @include phone
  {
    width: 70px;
  }
  // &.sticky
  // {
  //   position: absolute;
  // }
  img
  {
    width: 100%;
    + img
    {
      display: none;
    }
  }
  &:hover
  {
    transform: scale(1.1);
    img
    {
      display: none;
      + img
      {
        display: block;
      }
    }
  }
}

.home-getinfo
{
  margin-top: 30px;
  padding-bottom: 70px;
  padding-top: 10px;
  // overflow: hidden;
  background-color: #e7f6db;
  background-image: url(../images/img-info-bg.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 120% 100%;
  position: relative;
  @include pad
  {
    background-image: none;
    padding-bottom: 150px;
  }
  @include phone
  {
    padding-bottom: 100px;
  }
  .star
  {
    position: absolute;
    width: 115px;
    bottom: 0;
    left: 10%;
    overflow: hidden;
    // transform: translateY(40%);
    @include pad
    {
      display: none;
    }
    img
    {
      width: 100%;
      transform: translateY(40%);
    }
  }
  .title
  {
    transform: translateY(-50%)!important;
    margin-left: auto;
    margin-right: auto;
    max-width: 510px;
    img
    {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }
  .container
  {
    position: relative;
  }
  .form-wrap
  {
    display: block;
    margin: 0 auto;
    max-width: 750px;
    form
    {
      max-width: 100%;
      @include phone
      {
        font-size: 14px;
      }
      .form-floating
      {
        margin: 0;
      }
      label.required
      {
        display: flex;
        &:after
        {
          display: inline-block;
          content: "*";
          color: red;
          transform: translate(3px,-3px);
          text-align: right;
        }
      }
      .work-row
      {
        > label
        {
          line-height: 32px;
        }
        > div
        {
          @include pad
          {
            flex-wrap: wrap;
          }
          > div
          {
            @include pad
            {
              margin-bottom: 10px;
              width: calc(50% - 1rem);
            }
          }
        }
      }
      button[type="submit"]
      {
        display: block;
        max-width: 100%;
        width: 400px;
        margin-left: auto;
        margin-right: auto;
        font-size: 1rem;
        @include phone
        {
          width: 100%;
        }
      }
    }
  }
}