.page-member{
  position: relative;
  padding-top: 50px;
  padding-bottom: 10%;
  min-height: calc(100vh - 60px);
  // min-height: 1000px;
  background-image: url(../images/img-member-bg-2.svg),url(../images/img-member-bg-1.svg);
  background-repeat: no-repeat;
  background-size: cover;
  @include pad{
    background: $white;
    height: auto;
    min-height: 0;
  }
  .background-info{
    position: absolute;
    top: 95px;
    left: 40px;
    width: calc(100% - 80px);
    @include pad{
      display: none;
    }
    img{
      width: 100%;
    }
    .info{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 20px 20px 45px;
      position: absolute;
      top: -25px;
      left: 0;
      @include size(516px,185px);
      background-image: url(../images/img-member-text-1.svg),url(../images/img-member-text-2.svg);
      background-repeat: no-repeat;
      background-size: 508px auto;
      background-position: left top,8px 8px;
      @include mobile{
        transform: scale(0.7) translate(-2vw,2vw);
        transform-origin: left top;
      }
      p{
        margin: 0;
        color: $black;
        font-size: rem(21);
      }
    }
  }
}

form#login,
form#forgot{
  margin-bottom: 500px;
  @include pad{
    margin-bottom: 0;
  }
}

.form-wrap{
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 2;
  &.center{
    justify-content: center;
    @include pad(min){
      padding-top: 5vh;
    }
  }
  .tabs{
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0 0 40px;
    li{
      list-style-type: none;
      a{
        display: block;
        text-align: center;
        line-height: 1;
        padding: 10px 15px;
        font-size: rem(24);
        color: $darkGray;
        font-weight: 500;
        text-decoration: none;
        position: relative;
        &:after{
          content: "";
          display: block;
          position: absolute;
          top: 100%;
          left: 0;
          @include size(100%,8px);
          border-radius: 50px;
          background-color: $darkGreen;
          opacity: 0;
        }
      }
      &.active{
        a{
          color: $black;
          &:after{
            opacity: 1;
          }
        }
      }
    }
  }
  form:not(.default){
    width: 500px;
    padding: 30px 70px 40px;
    border: solid 4px $black;
    box-shadow: 6px 6px 0px $black;
    border-radius: 10px;
    background-color: $white;
    max-width: 100%;
    position: relative;
    @include pad{
      padding: 0 0 40px;
      margin: 0 auto;
      box-shadow: none;
      border: none;
    }
  }
  form.admin{
    width: 100%;
    .inputs{
      padding: 30px 50px;
      border-radius: 5px;
      box-shadow: 0 0 15px rgba($black,0.15);
      @include pad{
        padding: 0 0 20px;
        margin: 0 auto;
        box-shadow: none;
        border: none;
      }
    }
    a.reset-pwd{
      display: inline-block;
      margin: 10px 0 0;
      text-decoration: none;
      color: $darkGreen;
      font-size: rem(16);
    }
  }
  form{
    h3{
      padding-top: 10px;
      margin-bottom: 35px;
      font-weight: 500;
      font-size: rem(24);
    }
    p{
      margin-bottom: 20px;
      font-size: rem(16);
      color: #6A6A6A;
    }
    hr{
      margin-top: 20px;
      margin-bottom: 20px;
      border-top-color: $border;
      @include pad(min){
        margin-left: -25px;
        width: calc(100% + 50px);
      }
    }
    .back{
      display: block;
      position: absolute;
      top: 40px;
      left: 30px;
      @include size(30px);
      background-image: url(../images/img-arrow-back.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      @include pad{
        top: 10px;
        left: 0;
      }
    }
    .form-floating{
      margin: 10px 0;
      .is-invalid:not(label){
        margin-bottom: 30px;
      }
      label{
        color: $text;
        background: none;
        padding-top: 12px;
        padding-bottom: 0;
      }
      .form-control,.form-select{
        padding-bottom: 5px;
        height: 52px;
        min-height: 52px;
        border-radius: 5px;
        &::placeholder{
          color: $white
        }
      }
    }
    label.is-invalid.is-invalid{
      transform: none;
      padding: 0;
      height: auto;
      top: 100%;
      left: 10px;
      margin-top: 3px;
      color: $red;
      font-size: rem(12);
      &:after{
        display: none;
      }
    }
    input[type="radio"],input[type="checkbox"]:not([role="switch"]){
      @include size(24px);
      cursor: pointer;
      &.form-check-input{
        &:checked{
          background-color: $darkGreen;
          border-color: $darkGreen;
        }
      }
    }
    .form-switch .form-check-input{
      width: 45px;
      height: 25px;
      &:checked{
        background-color: $darkGreen;
      }
    }
    .row{
      margin-bottom: 10px;
      .row{
        margin-bottom: 0;
      }
    }
    .other{
      display: none;
    }
    .col-form-label{
      line-height: 52px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .form-control,.form-select{
      min-height: 52px;
      &::placeholder{
        color: $gray;
      }
    }
    .form-control.search{
      padding-left: 50px;
      background-image: url(../images/img-search.svg);
      background-repeat: no-repeat;
      background-size: 20px auto;
      background-position: 15px center;
    }
    .last-label{
      position: relative;
      label:not(.is-invalid){
        position: absolute;
        top: 14px;
        right: 15px;
        color: $text;
      }
      input.form-control{
        padding-right: 30px;
        &.is-invalid,&.is-valid{
          background-image: none;
        }
      }
    }
    .form-check-inline{
      display: inline-flex;
      align-items: center;
      label{
        padding-left: 8px;
        margin-top: 2px;
        cursor: pointer;
        a{
          margin-left: 5px;
          text-decoration: none;
          color: $darkGreen;
        }
      }
    }
    .reqiured-label-remove{
      label{
        color: $black!important;
      }
      label.is-invalid{
        display: none!important;
      }
    }
    .buttons{
      .links{
        padding: 10px 0 20px;
        text-align: right;
        a{
          margin-left: 10px;
          text-decoration: none;
          font-size: rem(16);
          color: $darkGreen;
          &:hover{
            border-bottom: dashed 1px $darkGreen;
          }
        }
      }
      button{
        width: 100%;
        + .links{
          padding-bottom: 0;
          margin-top: 20px;
          text-align: center;
          a{
            margin: 0 10px;
            font-size: rem(18);
          }
        }
      }
    }
  }
}