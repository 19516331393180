$black: #30352E;
$white: #ffffff;
$gray: #C4C4C4;
$darkGray: #A9A9A9;
$yellow: #FFEE55;
$green: #ceeb3e;
$red: #dc3545;
$learnBg: #EEF6C1;
$darkGreen: #00904F;
$purple: #7B61FF;
$header: #7A7A7A;
$border: #C8C8C8;
$background: #F4F4F4;
$text: #7A7A7A;
$title: #00904f;
