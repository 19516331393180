[class*="kos--"]
  opacity: 0
  transition-property: opacity,transform
  transition-duration: 1s
  pointer-events: none
  &.kos--animated
    opacity: 1
    pointer-events: all
  &.kos--only-desk
    +pad
      opacity: 1!important
      transform: none!important

.kos--fadeDown
  transform: translate3d(0,-50px,0)
  &.kos--animated
    transform: translateZ(0)
  
.kos--fadeUp
  transform: translate3d(0,50px,0)
  &.kos--animated
    transform: translateZ(0)

.kos--fadeLeft
  transform: translate3d(-50vw,0,0)
  &.kos--animated
    transform: translateZ(0)

.kos--fadeRight
  transform: translate3d(50vw,0,0)
  &.kos--animated
    transform: translateZ(0)

.kos--fadeScale
  transform: scale(0)
  &.kos--animated
    transform: scale(1)
