#policy-info{
  .modal-dialog{
    max-width: calc(100% - 20px);
    width: 760px;
  }
}

.policy-info{
  padding: 40px 50px 60px;
  @include phone{
    padding: 40px 20px;
  }
  .info{
    padding-right: 15px;
    height: 60vh;
    max-height: 470px;
    overflow-y: scroll;
    &::-webkit-scrollbar{
      background-color: $white;
      width: 8px;
    }
    &::-webkit-scrollbar-thumb{
      background-color: $black!important;
      border-radius: 10px;
    }
    p{
      margin-bottom: 20px;
    }
  }
}

.pls-login,.edit-pwd{
  padding: 30px 70px 50px;
}

.edit-pwd{
  @include phone{
    padding: 30px 40px 40px;
  }
}

.modal-content{
  // border-radius: 0;
  border: solid 4px $black;
  box-shadow: 6px 6px 0 $black;
  .btn-close{
    padding: 0;
    position: absolute;
    top: 20px;
    right: 20px;
    color: $black;
    @include size(30px);
    opacity: 1;
    background-size: 30px 30px;
  }
  .title{
    margin-bottom: 30px;
    h3{
      margin: 0;
      font-size: rem(30);
      font-weight: 500;
      line-height: 40px;
    }
  }
  p{
    margin-bottom: 40px;
    font-size: rem(16);
    line-height: 24px;
  }
}

.edit-pwd{
  form#edit-pwd-form,form#edit-pwd-admin-form{
    .form-floating.form-floating{
      margin-bottom: 10px;
      .is-invalid:not(label){
        margin-bottom: 30px;
      }
      > label{
        color: $text;
        background: none;
        padding-top: 12px;
        padding-bottom: 0;
      }
      .form-control,.form-select{
        padding-bottom: 5px;
        height: 52px;
        min-height: 52px;
        border-radius: 5px;
        &::placeholder{
          color: $white
        }
      }
    }
    label.is-invalid.is-invalid{
      transform: none;
      padding: 0;
      height: auto;
      top: 100%;
      left: 10px;
      margin-top: 3px;
      color: $red;
      font-size: rem(12);
      &:after{
        display: none;
      }
    }
    .buttons{
      padding-top: 30px;
      button{
        width: 100%;
      }
    }
  }
  hr{
    margin: 20px 0 20px -20px;
    width: calc(100% + 40px);
  }
}
