.page-course{
  padding-bottom: 95px;
  @include media(max,1000){
    padding-bottom: 0;
  }
  .course-wrap{
    align-items: flex-start;
    @include media(max,1000){
      flex-direction: column;
    }
  }
  .class-list{
    padding-right: 50px;
    @include media(min,1000){
      width: 63%;
    }
    @include media(max,1000){
      padding-right: 0;
      margin-bottom: 30px;
    }
    @include pad{
      padding: 0 20px;
    }
  }
  .class-work{
    padding: 30px 40px 40px;
    width: 37%;
    background-color: $background;
    position: relative;
    @include media(min,1400){
      padding: 30px 60px 60px;
    }
    @include media(max,1000){
      width: 100%;
      background: none;
    }
    @include pad{
      padding-left: 15px;
      padding-right: 15px;
    }
    @include phone{
      padding-left: 20px;
      padding-right: 20px;
    }
    &:after{
      content: "";
      position: absolute;
      right: 50%;
      top: 0;
      @include size(100vw,100%);
      transform: translateX(50%);
      background-color: $background;
      z-index: -1;
      display: none;
      @include media(max,1000){
        display: block;
      }
    }
    .title{
      padding: 50px 15px 20px;
      margin: 0 auto 30px;
      width: 195px;
      background-image: url(../images/img-class-work-title.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      h3{
        font-size: rem(26);
        line-height: 1;
        font-weight: 500;
      }
    }
    .content{
      margin-bottom: 30px;
      p{
        margin-bottom: 0;
        font-size: rem(16);
        line-height: 28px;
        text-align: center;
        color: $black;
      }
    }
    .btn{
      width: 100%;
    }
  }
  .course{
    display: block;
    margin-right: 20px;
    margin-bottom: 20px;
    width: calc(50% - 10px);
    @include pad{
      margin-right: 0;
      width: 100%;
    }
    &:nth-of-type(even){
      margin-right: 0;
    }
    a{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      padding: 15px;
      height: 100%;
      text-decoration: none;
      background-color: $white;
      border: solid 4px $black;
      @include t;
      &:hover{
        transform: translate(-5px,-5px);
        box-shadow: 5px 5px 0 $black;
      }
    }
    figure,h3,p{
      width: 100%;
    }
    figure{
      margin-bottom: 15px;
      position: relative;
      overflow: hidden;
      border-radius: 10px;
      &:after{
        content: "";
        display: block;
        padding-bottom: 60%;
      }
      img{
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        @include size(100%);
        object-fit: cover;
      }
    }
    h3{
      margin-bottom: 10px;
      font-size: rem(20);
      font-weight: 400;
      color: $black;
    }
    p{
      margin-bottom: 15px;
      color: $black;
    }
    span{
      display: flex;
      align-items: center;
      font-size: rem(14);
      color: $black;
      img{
        margin-right: 10px;
        width: 20px;
      }
    }
  }
  .class-prepare{
    margin: 0 auto;
    width: 400px;
    @include media(max,1000){
      margin-bottom: 100px;
    }
  }
}

.page-video-banner{
  margin-bottom: 30px;
  padding-top: 85px;
  background-image: url(../images/img-course-detail-bg.png);
  background-size: cover;
  background-position: center top;
  @include pad{
    padding-top: 55px;
  }
  h2{
    margin-bottom: 50px;
    font-size: rem(40);
    @include pad{
      margin-bottom: 40px;
    }
    br{
      display: none;
      @include pad{
        display: block;
      }
    }
  }
  .my-video{
    width: 100%;
    height: auto;
    --vjs-theme-fantasy--primary: #{$darkGreen};
    @include pad{
      margin-left: -20px;
      width: calc(100% + 40px);
    }
    &:before{
      content: "";
      display: block;
      padding-bottom: 56.5%;
    }
    .vjs-poster{
      img{
        @include size(100%);
        object-fit: cover;
      }
    }
    .vjs-duration,.vjs-current-time,.vjs-time-divider{
      display: block;
    }
    .vjs-time-divider{
      padding: 0;
      width: auto;
      min-width: 0;
    }
    .vjs-big-play-button{
      background-color: $black;
      color: $white;
      border-radius: 50%;
      font-size: 50px;
      // left: 70px;
      // top: auto;
      // bottom: 25px;
      span:before{
        padding-left: 3px;
      }
    }
    &:hover{
      .vjs-big-play-button{
        background-color: $white;
        color: $darkGreen;
      }
    }
    // .vjs-big-play-button{
    // }
  }
  .video-wrap{
    position: relative;
    overflow: hidden;
    @include pad{
      margin-left: -20px;
      width: calc(100% + 40px);
    }
    .poster{
      position: absolute;
      top: 0;
      left: 0;
      @include size(100%);
      z-index: 2;
      img{
        @include size(100%);
        object-fit: cover;
      }
    }
    &:after{
      content: "";
      display: block;
      padding-bottom: 56.35%;
    }
    iframe{
      position: absolute;
      top: 0;
      left: 0;
      @include size(100%);
    }
    .ytp-impression-link{
      display: none !important;
    }
  }
  .login-wrap{
    width: 100%;
    position: relative;
    @include pad{
      margin-left: -20px;
      width: calc(100% + 40px);
    }
    &:before{
      content: "";
      display: block;
      padding-bottom: 56.5%;
    }
    img{
      position: absolute;
      top: 0;
      left: 0;
      @include size(100%);
      object-fit: cover;
    }
    .cover{
      padding: 20px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include size(100%);
      background-color: rgba($white,0.5);
      backdrop-filter: blur(10px);
      p{
        margin-bottom: 45px;
        font-size: rem(20);
      }
      a{
        width: 100%;
        max-width: 360px;
        @include pad{
          max-width: 200px;
        }
      }
    }
  }
}

.page-course-detail{
  @include pad(min){
    padding-bottom: 50px;
  }
  .head{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 85px;
    .teacher,.info{
      p{
        padding-left: 15px;
        margin: 0;
        line-height: rem(24);
      }
    }
    .teacher{
      display: flex;
      align-items: center;
      margin-right: 10px;
      width: 290px;
      @include mobile{
        width: 200px;
      }
      @include pad{
        margin-right: 0;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: solid 1px $border;
        width: 100%;
      }
      picture{
        display: block;
        position: relative;
        width: 55px;
        overflow: hidden;
        border-radius: 50%;
        background-color: $white;
        &:after{
          content: "";
          display: block;
          padding-bottom: 100%;
        }
        img{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          @include size(100%);
          object-fit: cover;
        }
      }
      p{
        font-size: rem(20);
      }
    }
    .info{
      display: flex;
      align-items: center;
      margin: 0 10px;
      @include pad{
        margin: 0 0 20px;
        padding-bottom: 20px;
        width: 50%;
        border-bottom: solid 1px $border;
        &:last-of-type{
          justify-content: flex-end;
        }
      }
      img{
        width: 30px;
        flex-shrink: 0;
      }
      p{
        font-size: rem(16);
      }
    }
    .btn{
      @include pad{
        width: 100%;
      }
    }
  }
  .body{
    @include pad{
      margin-left: -20px;
      width: calc(100% + 40px);
    }
    .info{
      padding: 60px 30px 40px;
      position: relative;
      border-radius: 10px;
      @include pad{
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 0;
      }
      h3{
        position: absolute;
        bottom: 100%;
        left: 0;
        transform: translateY(50%);
      }
      li,p{
        line-height: 30px;
        color: $black;
      }
      ol,ul{
        padding-left: 20px;
        li{
          font-size: rem(16);
        }
      }
      + .info{
        margin-top: 85px;
      }
      &.learn-important{
        background-color: $learnBg;
        
      }
    }
  }
}