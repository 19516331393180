@mixin media($type,$start,$end:0) {
  @if $type == "max" {
    @media screen and (max-width: #{$start}px) {
      @content;
    }
  }
  @if $type == "min" {
    @media screen and (min-width: #{$start}px) {
      @content;
    }
  }
  @if $type == "interval" {
    @media (min-width: #{$start}px) and (max-width: #{$end}px) {
      @content;
    }
  }
}
@mixin mobile($type:max) {
  @media screen and (#{$type}-width: 960px) {
    @content;
  }
}
@mixin pad($type:max) {
  @media screen and (#{$type}-width: 767px) {
    @content;
  }
}
@mixin phone($type:max) {
  @media screen and (#{$type}-width: 576px) {
    @content;
  }
}
@mixin mini($type:max) {
  @media screen and (#{$type}-width: 480px) {
    @content;
  }
}
@mixin t($t:0.5) {
  transition-duration: #{$t}s;
}
@mixin size($w,$h:$w) {
  width: $w;
  height: $h;
}
// 限制行數(...)
@mixin lineClamp($n:2) {
  display: -webkit-box;
  -webkit-line-clamp: $n;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
