footer#footer{
  padding: 30px 0;
  background-color: $green;
  .container{
    position: relative;
  }
  h3{
    margin-bottom: 14px;
    font-size: rem(18);
    color: $title;
    font-weight: 500;
    @include media(max,1000){
      margin-bottom: 20px;
      text-align: center;
    }
  }
  .infos{
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    width: 62%;
    @include media(max,1000){
      padding-bottom: 20px;
      width: 100%;
      justify-content: center;
    }
    @include pad{
      padding-bottom: 10px;
      flex-direction: column;
      align-items: center;
    }
    li{
      display: flex;
      list-style-type: none;
      font-size: rem(16);
      color: $black;
      margin-right: 30px;
      @include pad{
        margin-right: 0;
        margin-bottom: 10px;
      }
      span{
        display: block;
        padding-right: 10px;
        margin-right: 10px;
        border-right: solid 2px $title;
        flex-shrink: 0;
        @include pad{
          width: 100px;
          text-align: right;
        }
      }
      a{
        color: $black;
        text-decoration: none;
        @include pad{
          width: 240px;
        }
      }
    }
  }
  p.viewers{
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 20px;
    font-size: rem(16);
    margin-bottom: 0;
    @include pad{
      margin-bottom: 20px;
      position: relative;
      top: auto;
      bottom: auto;
      right: auto;
      width: 100%;
      justify-content: center;
      font-size: rem(14);
    }
    span{
      display: block;
      margin-right: 4px;
      background-color: $white;
      @include size(26px);
      text-align: center;
      line-height: 26px;
      border-radius: 8px;
      &:first-of-type{
        margin-left: 11px;
      }
    }
  }
  p.copyright{
    margin: 0;
    position: absolute;
    bottom: 0;
    right: 20px;
    font-size: rem(14);
    @include media(max,1000){
      position: relative;
      right: 0;
      text-align: center;
    }
  }
}